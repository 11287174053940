<template>
    <svg
        class="svg-logo"
        width="1179"
        height="307"
        viewBox="0 0 1179 307"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M229.1 175.049C229.1 175.523 229.134 175.998 229.134 176.473C229.134 185.66 224.015 193.321 216.896 198.135C216.421 198.474 215.438 199.118 215.438 199.118L131.433 255.8C121.093 262.784 107.6 262.784 97.2607 255.8L0 190.236V235.663L97.2946 301.295C107.634 308.244 121.127 308.244 131.466 301.295L218.387 242.647C225.134 238.104 229.168 230.51 229.168 222.374V176.846C229.168 176.235 229.168 175.625 229.1 175.049Z"
            fill="white"
        />
        <path
            d="M229.1 175.05C228.626 168.168 225.473 161.795 220.354 157.252C220.218 157.116 220.049 156.981 219.913 156.879C219.913 156.879 219.846 156.845 219.812 156.811C219.1 156.201 218.354 155.659 217.574 155.116L114.381 85.4844V130.945L178.283 174.067L215.438 199.153C216.184 198.644 216.794 198.238 217.506 197.763C224.388 193.186 229.1 185.423 229.1 176.507C229.1 176.033 229.1 175.558 229.066 175.084L229.1 175.05Z"
            fill="white"
        />
        <path
            d="M0 85.4844V131.555C0 139.691 4.03417 147.285 10.7804 151.828L114.381 221.731V176.474L13.5602 108.028C5.49189 103.723 0 95.2477 0 85.4844Z"
            fill="white"
        />
        <path
            d="M131.466 6.18881C121.127 -0.794708 107.6 -0.794708 97.2946 6.18881L11.3567 64.2266C4.50878 68.8032 0 76.6003 0 85.4483C0 95.2456 5.49189 103.721 13.5602 108.026L50.9865 82.8041L97.2946 51.6156C107.634 44.666 121.127 44.666 131.466 51.6156L229.168 117.518V72.0576L131.466 6.18881Z"
            fill="white"
        />
        <path d="M710.691 70.5684H690.012V236.952H710.691V70.5684Z" fill="white" />
        <path
            d="M605.737 208.715L564.413 110.912H541.462L594.279 235.87L573.193 285.737L572.922 286.348H595.364L669.776 110.912H647.096L605.737 208.715Z"
            fill="white"
        />
        <path
            d="M831.14 117.658C821.817 111.861 810.562 108.945 797.68 108.945C784.798 108.945 773.746 111.895 764.356 117.658C754.965 123.455 747.643 131.354 742.625 141.185C737.608 150.982 735.065 162.034 735.065 174.068C735.065 186.103 737.744 196.985 742.998 206.85C748.287 216.749 755.813 224.682 765.373 230.479C774.933 236.276 786.221 239.191 798.934 239.191C813.85 239.191 826.224 235.564 835.716 228.411C845.174 221.292 852.192 211.969 856.565 200.748L857.141 199.29H834.564L834.258 199.867C831.038 205.799 826.495 210.715 820.698 214.512C814.935 218.275 807.714 220.173 799.171 220.173C791.103 220.173 783.95 218.41 777.848 214.986C771.746 211.528 766.83 206.816 763.203 200.951C760.016 195.765 757.813 190.035 756.694 183.899L756.457 182.611H860.328V174.102C860.328 162.068 857.786 151.016 852.768 141.219C847.751 131.387 840.462 123.489 831.14 117.692V117.658ZM797.68 127.964C809.24 127.964 818.698 131.455 825.749 138.337C832.224 144.677 836.496 152.541 838.394 161.762L838.666 163.085H756.762L757.067 161.762C759.101 152.575 763.372 144.677 769.78 138.337C776.729 131.455 786.12 127.964 797.714 127.964H797.68Z"
            fill="white"
        />
        <path
            d="M1094.31 70.5684H1036.04V238.919H1094.31C1140.72 238.919 1178.49 201.153 1178.49 154.743C1178.49 108.334 1140.72 70.5684 1094.31 70.5684ZM1094.31 218.205H1056.71V91.2816H1094.31C1129.3 91.2816 1157.77 119.758 1157.77 154.743C1157.77 189.729 1129.3 218.205 1094.31 218.205Z"
            fill="white"
        />
        <path
            d="M957.794 130.573L955.523 130.2L1000.85 89.5534L1000.98 89.4178V70.5352H890.196V91.2484H967.964L924.334 130.336L924.198 130.471V150.168H943.996C965.727 150.168 987.762 161.355 987.762 182.678C987.762 202.747 968.54 218.477 943.996 218.477C924.47 218.477 907.146 207.392 901.858 191.526L901.519 190.509L882.331 196.916L881.891 197.052L882.23 198.069C890.298 222.274 915.689 239.157 943.996 239.157C980.134 239.157 1008.44 214.341 1008.44 182.644C1008.44 168.813 1003.09 156.439 992.915 146.879C983.931 138.404 971.761 132.709 957.794 130.505V130.573Z"
            fill="white"
        />
        <path
            d="M490.678 70.5691H469.897V110.911H444.573V129.454H469.897V205.188C469.897 222.85 484.271 237.19 501.899 237.19H526.036V218.206H505.052C497.119 218.206 490.644 211.765 490.644 203.798V129.42H526.036V110.877H490.644V70.5352L490.678 70.5691Z"
            fill="white"
        />
        <path
            d="M406.197 152.403C398.603 149.419 388.433 146.368 375.924 143.351C366.262 141.148 358.533 139.012 352.973 137.046C347.38 135.079 342.634 132.299 338.871 128.74C335.108 125.214 333.209 120.57 333.209 114.942C333.209 107.247 336.599 100.908 343.278 96.0259C349.956 91.1442 360.16 88.6695 373.551 88.6695C381.111 88.6695 388.942 89.8221 396.841 92.1273C404.366 94.2969 411.587 97.5175 418.266 101.653L419.351 102.331L427.317 82.9742L426.605 82.4656C419.893 77.923 411.858 74.3634 402.638 71.9226C393.145 69.38 383.348 68.0918 373.551 68.0918C359.482 68.0918 347.515 70.2614 337.989 74.5329C328.497 78.8044 321.276 84.6014 316.598 91.7544C311.92 98.8735 309.547 107.01 309.547 115.892C309.547 126.536 312.326 135.079 317.784 141.351C323.242 147.623 329.921 152.301 337.616 155.318C345.278 158.335 355.516 161.352 367.991 164.369C377.924 166.742 385.721 168.912 391.179 170.811C396.603 172.709 401.214 175.455 404.943 178.981C408.638 182.506 410.502 187.151 410.502 192.778C410.502 200.474 407.079 206.711 400.332 211.322C393.586 215.966 383.179 218.305 369.483 218.305C359.075 218.305 348.803 216.508 338.938 212.949C329.548 209.559 321.649 205.152 315.479 199.829L314.428 198.914L305.479 217.797L306.055 218.339C312.665 224.306 321.785 229.255 333.107 233.018C344.735 236.883 356.973 238.849 369.483 238.849C383.585 238.849 395.62 236.713 405.248 232.51C414.91 228.306 422.232 222.509 426.978 215.288C431.724 208.067 434.131 199.999 434.131 191.286C434.131 180.811 431.351 172.37 425.893 166.166C420.435 159.996 413.791 155.318 406.197 152.301V152.403Z"
            fill="white"
        />
    </svg>
</template>
<style lang="less">
    .svg-logo {
        display: flex;
    }
</style>
