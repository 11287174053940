// 待大锤的请求库调好，删除此文件
import { hash } from 'ohash';
import Store from 'store';
// import type { Router, RouteLocationNormalizedLoaded } from 'vue-router';
import Cookies from 'js-cookie';
import { useLocaleLanguage } from '@/composables/useLocale';
import requestConfig from '@/configs/request.config.json';

export interface IHttpResp<T = any> {
    code: string | number;
    message: string;
    state: boolean;
    data?: T;
}

const fetch = (url: string, options?: any): Promise<IHttpResp> => {
    const reqUrl = requestConfig.requestUrl + url;
    const locale = useLocaleLanguage();
    const route = useRoute();
    const router = useRouter();
    const token = useCookie('token').value;
    const key = hash(JSON.stringify(options) + '_' + url + locale.value);
    // TODO: dachui 需要修改为usefetch
    return new Promise((resolve, reject) => {
        $fetch(reqUrl, {
            headers: {
                Authorization: token || Store.get('token') || '', // token
                'accept-language': locale.value,
            },
            ...options,
            key,
            onResponse({ response }) {
                if (response.status === 401) {
                    console.log('401：未登录权限不足');
                    // redirectToLogin(router, route);
                    return;
                }
                if (!response._data.code) {
                    resolve(response._data);
                    return;
                }

                console.error(response._data.message);
                reject(response._data);
            },
            onResponseError({ response }) {
                console.log(response, response._data, response.status, 'response');
                if (response.status !== 401) {
                    console.error(response._data.message);
                } else {
                    console.log('401：未登录权限不足');
                    // redirectToLogin(router, route);
                    return;
                }
                reject(response._data);
            },
        }).catch((err: any) => {
            console.error(err);
            reject(err);
        });
    });
};

const fetchHubspot = (url: string, options?: any): Promise<IHttpResp> => {
    const reqUrl = url;
    const locale = useLocaleLanguage();
    const token = useCookie('token').value;
    const key = hash(JSON.stringify(options) + '_' + url + locale.value);
    return new Promise((resolve, reject) => {
        $fetch(reqUrl, {
            headers: {
                Authorization: token || Store.get('token') || '', // token
                'accept-language': locale.value,
            },
            ...options,
            key,
            onResponse({ response }) {
                if (response.status === 401) {
                    console.log('401：未登录权限不足');
                    // redirectToLogin(router, route);
                    return;
                }
                if (response.ok) {
                    resolve(response._data);
                    return;
                }

                console.error(response._data.inlineMessage);
                reject(response._data);
            },
            onResponseError({ response }) {
                console.error(response._data.inlineMessage);
                reject(response._data);
            },
        }).catch((err: any) => {
            console.error(err);
            reject(err);
        });
    });
};

function redirectToLogin(router: Router, route: RouteLocationNormalizedLoaded) {
    // 清除cookie中的token
    Cookies.remove('token');
    if (/login/.test(route.path)) return;
    let backUrl = null;
    if (route.query.backUrl) {
        backUrl = route.query.backUrl;
    } else if (route.path !== '/') {
        // 非首页支持backUrl
        backUrl = encodeURIComponent(route.fullPath);
    }
    if (process.server) {
        router.push(`/login${backUrl ? '?backUrl=' + backUrl : ''}`);
    } else {
        window.location.href = `/login${backUrl ? '?backUrl=' + backUrl : ''}`;
    }
}

export default new (class Http {
    get<T = any>(url: string, params?: any): Promise<IHttpResp<T>> {
        return fetch(url, { method: 'get', params });
    }

    post<T = any>(url: string, body?: any): Promise<IHttpResp<T>> {
        return fetch(url, { method: 'post', body });
    }

    put<T = any>(url: string, body?: any): Promise<IHttpResp<T>> {
        return fetch(url, { method: 'put', body });
    }

    delete<T = any>(url: string, body?: any): Promise<IHttpResp<T>> {
        return fetch(url, { method: 'delete', body });
    }

    // 用于请求hubspot api
    hspost<T = any>(url: string, body?: any): Promise<IHttpResp<T>> {
        return fetchHubspot(url, { method: 'post', body });
    }
})();
